import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { ApiService } from './api.service';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FiledragDirective } from './filedrag.directive';
import { BomAutoMappingComponent } from './bom-auto-mapping/bom-auto-mapping.component';
import { FormsModule } from '@angular/forms';
@NgModule({
  declarations: [AppComponent, FiledragDirective, BomAutoMappingComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), IonicStorageModule.forRoot(), AppRoutingModule, HttpClientModule,FormsModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, ApiService],
  bootstrap: [AppComponent],
})
export class AppModule {}
