import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { HttpClient, HttpErrorResponse , HttpHeaders ,HttpParams } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { Observable } from "rxjs";
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {base64StringToBlob} from 'blob-util';
import { Blob } from 'aws-sdk/lib/dynamodb/document_client';
import { saveAs } from 'file-saver';
import { DomSanitizer } from '@angular/platform-browser';

import * as AWS from 'aws-sdk';
import * as S3 from 'aws-sdk/clients/s3';
import { v1 as uuidv1 } from 'uuid';

import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  httpOptions: any;
  clientId: any;
  tokenCredentials: any ;
  messageBody: any;
  // httpUrl2 = "http://34.205.54.220:15000/bcv";
  // httpUrl = "https://bcvbackend.app.protoexpress.com/bom";

  // in staging use bcvbackend API
  httpUrl = "https://bcvstaging.app.protoexpress.com/bom";
  bomNewApI = 'https://customerstagingbackend.app.protoexpress.com';

  constructor(private httpClient: HttpClient,private storage: Storage,public alertController: AlertController, public _DomSanitizationService: DomSanitizer,) { 
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    storage.get('clientId').then((val) => {
      if(val){
        this.clientId = val;
      }else{
        storage.set('clientId', uuidv1());
      }

      // this.dologin();
    });

    storage.get('tokenCredentials').then((val) => {
      if(val){
        this.tokenCredentials = JSON.parse(val);
      }else{
        // this.dologin();
      }
    });
  }

  async alertFn(header,message){
    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }

  imageUpload(formData){
    let precessedData: any = true;
    let contentTypeData: any = true;
    var imghttpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'multipart/form-data',
        'processData': precessedData,
        "contentType": contentTypeData,
        // "Accept": "application/json",
        // "Content-Type": "*"
      })
      // responseType: 'text' as const
    }
    // console.log(formData);
    // return this.httpClient.post("https://0wce7napd6.execute-api.us-east-1.amazonaws.com/dev/bcvtest", formData, imghttpOptions);
    // return this.httpClient.post("https://0wce7napd6.execute-api.us-east-1.amazonaws.com/dev/partsearch", formData, imghttpOptions);
    return this.httpClient.post(this.httpUrl+"/compare", formData, imghttpOptions);
    // return this.httpClient.post(this.httpUrl+"/validate-compare", formData, imghttpOptions);
 }

  uploadMultipleSheet(formData){
    let precessedData: any = true;
    let contentTypeData: any = true;
    var sheethttpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'multipart/form-data'
        // 'processData': precessedData,
        // "contentType": contentTypeData
      })
    }
    return this.httpClient.post(this.bomNewApI+"/bom/mapHeaders", formData, sheethttpOptions);
  }

  updateColumnData(mapData){
    var sheethttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.httpClient.post(this.bomNewApI+"/bom/compare", mapData, sheethttpOptions);
  }

  

  public downloadComparisionResult(datObj): Observable<any> {
    let url = `${this.bomNewApI}${"/bom/export"}`;
    return this.httpClient.post(url, datObj, {
      responseType: "blob",
      headers: new HttpHeaders().append("Content-Type", "application/json")
    });
  }

  checkSession(sessEncData){
    var sheethttpOptions = {
      headers: new HttpHeaders({
        "Authorization":"bearer 0553b011-9511-40c2-91ce-dad3c4b2d80a",
        'Accept': 'application/json'
      })
    }
    return this.httpClient.get('https://staging.protoexpress.com/sierra-app/getWebSessionStatus?memberID='+ sessEncData, sheethttpOptions);
  }

  getUserDataSession(sessionEncData){
    return this.httpClient.get(this.bomNewApI+"/bom/sessionInfo?src=data="+sessionEncData, {});
  }

}
